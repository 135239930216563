import './style.css'
import './animate.css';
import Tabs from './components/tabs/Tabs';
import Carousel from './components/sliderEquipment/Carousel';
import { Navbar } from './components/Navbar';
import {Helmet} from "react-helmet";

export const About = () => {
    return (
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>О компании Кузет Technology System</title>
            <meta name="description" content="Узнайте больше о нашей компании. ✅ Мы обеспечиваем вашу безопасность по лучшим ценам★ Охрана объектов☎Пультовая охрана от Кузет Technology System"></meta>
        </Helmet>
          <div id="service_slider"> 
            <div id="slider_overlay" className="unselectable">
              Кузет
            </div> 
            <div className="slider slick-slider"> 
              <div className="slick-list draggable">
                <div className="slick-track">
                  <div className="item"> 
                    <div className="slide_content"> 
                      <h1>О компании</h1>
                    </div>
                  </div>
                </div>
              </div> 
            </div> 
          </div>

          <Navbar />
          
          <section id="page">
            <div className='content'>
              <div className="utp mb" style={{backgroundImage: "url(/utp.jpg)"}}> 
              </div>
            </div> 
          </section>








        </>
      )
    }        
