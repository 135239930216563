import './style.css'
import './animate.css';
import { Navbar } from './components/Navbar';
import {Helmet} from "react-helmet";

export const Contacts = () => {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Контакты компании. Свяжитесь с нами! | KTS Group</title>
            <meta name="description" content="Контакты нашей компании. Если у вас есть вопросы по услугам нашего охранного агентства - Свяжитесь с нами"></meta>
          </Helmet>
          <div id="service_slider"> 
            <div id="slider_overlay" className="unselectable">
              Кузет
            </div> 
            <div className="slider slick-slider"> 
              <div className="slick-list draggable">
                <div className="slick-track">
                  <div className="item"> 
                    <div className="slide_content"> 
                      <h1>Контакты</h1>
                    </div>
                  </div>
                </div>
              </div> 
            </div> 
          </div>

          <Navbar />
          
          <section id="page">
            <div className='content'>
              <div className="utp mb" style={{backgroundImage: "url(/utp.jpg)"}}> 
              </div>
            </div> 
          </section>








        </>
      )
    }        
